import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import PlayerSectionTwo from "../../component/section/playertwo";
import CtaSection from "../../component/section/cta";
import Cal from "../../component/section/mios/Calendario";
import CalFem from "../../component/section/mios/CalendarioFem";
import PageHeaderFem from "../../component/layout/pageheaderFem";

class CalendarioFem extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeaderFem title={'CALENDARIO FEMENINO'} curPage={'CALENDARIO'} />
                <CalFem />
                {/* <CtaSection imgUrl={'assets/images/cta/02.png'} /> */}
                <Footer />
            </Fragment>
        );
    }
}
 
export default CalendarioFem;