import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import { Link } from "react-router-dom";

const infosubtitle = "HISTÓRICAS";

class HistoricasFem extends Component {
  render() {
    const styles = {
      gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', // Dos columnas
        gridGap: '20px', // Espacio entre las cajas
        marginTop: '50px',
        justifyContent: 'center',
        padding: '20px',
      },
      card: {
        backgroundColor: '#f4f4f4',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
        height: '250px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
        cursor: 'pointer', // Para indicar que es clicable
      },
      cardTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#333',
      },
      cardLink: {
        textDecoration: 'none',
      },
      infoSection: {
        paddingTop: '200px', // Añade más padding arriba
        paddingBottom: '60px', // Padding inferior
      },
    };

    return (
      <Fragment>
        <Header />
        {/* <PageHeader title={'HISTÓRICAS'} curPage={'HISTORICAS'} /> */}
        <div className="info-section" style={styles.infoSection}>
          <div className="container">
            <div className="section-header">
              <h2>{infosubtitle}</h2>
            </div>

            <div style={styles.gridContainer}>
              <Link to="/historicoEquipoFem" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>Equipo</h3>
                </div>
              </Link>

              {/* <Link to="/historicas" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>EQUIPO</h3>
                </div>
              </Link> */}
            </div>
          </div>
        </div>

        <Footer />
      </Fragment>
    );
  }
}

export default HistoricasFem;
