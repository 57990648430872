import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import PlayerSectionTwo from "../../component/section/playertwo";
import CtaSection from "../../component/section/cta";
import Cal from "../../component/section/mios/Calendario";
import Cla from "../../component/section/mios/Clasificacion";
import ClaFem from "../../component/section/mios/ClasificacionFem";
import PageHeaderFem from "../../component/layout/pageheaderFem";

class ClasificacionFem extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeaderFem title={'CLASIFICACION FEMENINA'} curPage={'CLASIFICACION'} />
                <ClaFem />
                {/* <CtaSection imgUrl={'assets/images/cta/02.png'} /> */}
                <Footer />
            </Fragment>
        );
    }
}
 
export default ClasificacionFem;