import { Component, Fragment } from "react";

class SocialMediaEquipos extends Component {
  render() {
    const { insta } = this.props;

    return (
      <Fragment>
        <li>
        <a href={insta} target="_blank" rel="noopener noreferrer">
        <img
              src={`${process.env.PUBLIC_URL}/assets/images/general/instagram.png`}
              alt="instagram"
              style={{ width: "30px", height: "30px" }} // Añadir este estilo
            />
          </a>
        </li>
        {/* <li>
                    <a href="https://www.youtube.com/@LaLiga_3x3"><img src="assets/images/match/social-2.png" alt="youtube" /></a>
                </li>
                <li>
                    <a href="#"><img src="assets/images/match/social-3.png" alt="twitch" /></a>
                </li> */}
      </Fragment>
    );
  }
}

export default SocialMediaEquipos;
