import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";

const infosubtitle = "TEMPORADA 23/24 - EQUIPO";

class TemporadaEquipoFem extends Component {
  render() {
    const styles = {
      gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', // Dos columnas
        gridGap: '20px', // Espacio entre las cajas
        marginTop: '50px',
        justifyContent: 'center',
        padding: '20px',
      },
      card: {
        backgroundColor: '#f4f4f4',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
        height: '150px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
        cursor: 'pointer',
      },
      cardTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#333',
      },
      cardLink: {
        textDecoration: 'none', // Sin subrayado
      },
      infoSection: {
        paddingTop: '200px', // Añade más padding arriba
        paddingBottom: '60px', // Padding inferior
      },
    };

    return (
      <Fragment>
        <Header />
        {/* <PageHeader title={'TEMPORADA 23/24 - EQUIPO'} curPage={'ESTADÍSTICAS'} /> */}
        <div className="info-section" style={styles.infoSection}>
          <div className="container">
            <div className="section-header">
              <h2>{infosubtitle}</h2>
            </div>

            {/* Grid Section */}
            <div style={styles.gridContainer}>
              {/* Enlaces con el nombre correspondiente como parámetro */}
              <Link to="/detallesEstadisticasTEFem/partidos" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>PARTIDOS JUGADOS</h3>
                </div>
              </Link>
              <Link to="/detallesEstadisticasTEFem/puntos" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>PUNTOS ANOTADOS</h3>
                </div>
              </Link>
              <Link to="/detallesEstadisticasTEFem/rebotes" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>REBOTES TOTALES</h3>
                </div>
              </Link>
              <Link to="/detallesEstadisticasTEFem/asistencias" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>ASISTENCIAS TOTALES</h3>
                </div>
              </Link>
              <Link to="/detallesEstadisticasTEFem/puntosxpartido" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>PUNTOS POR PARTIDO</h3>
                </div>
              </Link>
              <Link to="/detallesEstadisticasTEFem/rebotesxpartido" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>REBOTES POR PARTIDO</h3>
                </div>
              </Link>
              <Link to="/detallesEstadisticasTEFem/asistenciasxpartido" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>ASISTENCIAS POR PARTIDO</h3>
                </div>
              </Link>
              <Link to="/detallesEstadisticasTEFem/tiros1_porcentaje" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>T1%</h3>
                </div>
              </Link>
              <Link to="/detallesEstadisticasTEFem/tiros2_porcentaje" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>T2%</h3>
                </div>
              </Link>
              <Link to="/detallesEstadisticasTEFem/tiroslibre_porcentaje" style={styles.cardLink}>
                <div style={styles.card}>
                  <h3 style={styles.cardTitle}>TL%</h3>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <Footer />
      </Fragment>
    );
  }
}

export default TemporadaEquipoFem;
