import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const NoticiaInicio = () => {
  const [noticias, setNoticias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Función para obtener las noticias de la API
  const obtenerNoticias = async () => {
    try {
      const response = await fetch(
        "https://laliga3x3.com/backend/api/listadoNoticias.php"
      );
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        setNoticias(data);
        setLoading(false);
      } else {
        setError("No se encontraron noticias");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error al obtener las noticias:", error);
      setError("Error al obtener los datos");
      setLoading(false);
    }
  };

  // useEffect para llamar a la API al cargar el componente
  useEffect(() => {
    obtenerNoticias();
  }, []);

  // Función para limpiar y recortar el texto a 40 palabras
  const getShortText = (text) => {
    const plainText = text
      .replace(/<[^>]*>?/gm, "")
      .replace(/\s+/g, " ")
      .trim();
    const words = plainText.split(" ");
    return words.length > 40 ? words.slice(0, 40).join(" ") + "..." : plainText;
  };

  if (loading) {
    return <p>Cargando las noticias...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="cta-section">
      <div className="container">
        <div className="cta-wrapper">
          <div className="row">
            {noticias.slice(0, 4).map((noticia, index) => (
              <div className="col-md-6 mb-4" key={index}>
                <div
                  className="cta-item d-flex align-items-center"
                  style={{
                    backgroundImage: "url(/assets/images/cta/bg.jpg)",
                    backgroundSize: "cover",
                    borderRadius: "10px",
                    padding: "30px",
                    minHeight: "350px", // Aumenta la altura mínima para ocupar más espacio vertical
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <p
                      className="theme-color text-uppercase ls-2"
                      style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    >
                      {noticia.fecha}
                    </p>
                    <h4
                      className="mb-3"
                      style={{ fontSize: "1.6rem", fontWeight: "bold" }}
                    >
                      {noticia.titulo}
                    </h4>
                    <div
                      className="noticia-texto"
                      style={{
                        fontSize: "1rem",
                        lineHeight: "1.6em",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2, // Mostrar solo 2 líneas de texto
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {getShortText(noticia.textoCorto)}
                    </div>
                    <Link
                      to={`/noticia/${noticia.id}`}
                      className="default-button"
                      style={{
                        marginTop: "20px",
                        padding: "1px 15px", // Botón aún más estrecho
                        fontSize: "0.8rem",
                      }}
                    >
                      <span>
                        Leer Más <i className="icofont-circled-right"></i>
                      </span>{" "}
                    </Link>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <img
                      src={`https://laliga3x3.com/backend/DOCS/noticias/${noticia.imagen1}`}
                      alt={`Imagen de ${noticia.titulo}`}
                      style={{
                        width: "150px", // Imagen más grande
                        height: "200px", // Imagen más larga
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoticiaInicio;
