
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Swiper from 'swiper';
import 'swiper/css';
import ScrollToTop from "./component/layout/scrolltop";
import AboutPage from "./pages/about";
import AchievementPage from "./pages/achievement";
import BlogPage from "./pages/blog";
import BlogDetails from "./pages/blog-single";
import BlogPageTwo from "./pages/blogtwo";
import ContactUs from "./pages/contact";
import GalleryPage from "./pages/gallery";
import GameListSection from "./pages/gamelist";
import GameListTwoSection from "./pages/gamelisttwo";
import HomePage from './pages/home';
import HomeTwo from './pages/hometwo';
import LogIn from "./pages/login";
import PartnerPage from "./pages/partner";
import ShopPage from "./pages/shop";
import ShopCart from "./pages/shopcart";
import ShopDetails from "./pages/shopdetails";
import SignUp from "./pages/signup";
import TeamPage from "./pages/team";
import TeamSinglePage from "./pages/team-single";
import ErrorPage from "./pages/errorpage";
import Inicio from "./pages/mios/inicio";
import Noticias from "./pages/mios/Noticias";
import Equipos from "./pages/mios/Equipos";
import Calendario from "./pages/mios/Calendario";
import Clasificacion from "./pages/mios/Clasificacion";
import Equipo from "./pages/mios/Equipo";
import Noticia from "./pages/mios/Noticia";
import Noticia2 from "./pages/mios/Noticia2";
import Patrocinadores from "./pages/mios/Patrocinadores";
import PoliticasPrivacidad from "./pages/mios/PoliticasPrivacidad";
import AvisoLegal from "./pages/mios/AvisoLegal";
import Copa3x3 from "./pages/mios/Copa3x3";
import Estadisticas from "./pages/mios/Estadisticas";
import Temporada from "./pages/mios/Temporada";
import Historicas from "./pages/mios/Historicas";
import TemporadaIndividuales from "./pages/mios/TemporadaIndividuales";
import DetallesEstadisticas from "./pages/mios/EstadisticasDetallesTI";
import TemporadaEquipo from "./pages/mios/TemporadaEquipos";
import DetallesEstadisticasTI from "./pages/mios/EstadisticasDetallesTI";
import DetallesEstadisticasTE from "./pages/mios/EstadisticasDetallesTE";
import DetallesEstadisticasHE from "./pages/mios/EstadisticasDetallesHE";
import HistoricoEquipo from "./pages/mios/HistoricoEquipos";
import Jornada from "./pages/mios/Jornada";
import JornadaPrueba from "./pages/mios/JornadaPrueba";
import Jugador from "./pages/mios/Jugador";
import ImagenesSede from "./pages/mios/ImagenesSede";
import Sorteo from "./pages/mios/Sorteo";
import { PoliticaPrivacidad } from "./pages/mios/PoliticaPrivacidadSorteo";
import { BasesDelSorteo } from "./pages/mios/BasesDelSorteo";
import EstadisticasFem from "./pages/mios/EstadisticasFem";
import CalendarioFem from "./pages/mios/CalendarioFem";
import ClasificacionFem from "./pages/mios/ClasificacionFem";
import EquiposFem from "./pages/mios/EquiposFem";
import HistoricasFem from "./pages/mios/HistoricasFem";
import HistoricoEquipoFem from "./pages/mios/HistoricoEquiposFem";
import TemporadaFem from "./pages/mios/TemporadaFem";
import TemporadaEquipoFem from "./pages/mios/TemporadaEquiposFem";
import TemporadaIndividualesFem from "./pages/mios/TemporadaIndividualesFem";
import DetallesEstadisticasTIFem from "./pages/mios/EstadisticasDetallesTIFem";
import DetallesEstadisticasTEFem from "./pages/mios/EstadisticasDetallesTEFem";
import DetallesEstadisticasHEFem from "./pages/mios/EstadisticasDetallesHEFem";
import EquipoFem from "./pages/mios/EquipoFem";

// import Footer from "./component/layout/footer";
// import Header from "./component/layout/header";
// import PageHeader from './component/layout/pageheader';
// import GameList from './component/section/gamelist';


function App() {
	return (
		// <div className="App">
		// 	<ShopPage />
		// </div>
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Inicio />} />
				<Route path="index-2" element={<HomeTwo />} />
				<Route path="about" element={<AboutPage />} />
				<Route path="gallery" element={<GalleryPage />} />
				<Route path="game-list" element={<GameListSection />} />
				<Route path="game-list2" element={<GameListTwoSection />} />
				<Route path="partners" element={<PartnerPage />} />
				<Route path="achievements" element={<AchievementPage />} />
				<Route path="team" element={<TeamPage />} />
				<Route path="team-single" element={<TeamSinglePage />} />
				<Route path="*" element={<ErrorPage />} />
				<Route path="shop" element={<ShopPage />} />
				<Route path="shop-single" element={<ShopDetails />} />
				<Route path="cart-page" element={<ShopCart />} />
				<Route path="blog" element={<BlogPage />} />
				<Route path="blog-2" element={<BlogPageTwo />} />
				<Route path="blog-single" element={<BlogDetails />} />
				<Route path="contact" element={<ContactUs />} />
				<Route path="login" element={<LogIn />} />
				<Route path="signup" element={<SignUp />} />
				<Route path="noticias" element={<Noticias />} />
				<Route path="equipos" element={<Equipos />} />
				<Route path="equiposFem" element={<EquiposFem />} />
				<Route path="calendario" element={<Calendario />} />
				<Route path="calendarioFem" element={<CalendarioFem />} />
				<Route path="clasificacion" element={<Clasificacion />} />
				<Route path="clasificacionFem" element={<ClasificacionFem />} />
				<Route path="/equipo/:id" element={<Equipo />} />
				<Route path="/equipoFem/:id" element={<EquipoFem />} />
				<Route path="/jornada/:id" element={<Jornada />} />
				<Route path="/jornadaPr/:id" element={<JornadaPrueba />} />
				<Route path="/noticia/:id" element={<Noticia />} />
				<Route path="/jugador/:id" element={<Jugador />} />
				<Route path="/imagenesSede/:id" element={<ImagenesSede />} />
				<Route path="/noticia2" element={<Noticia2 />} />
				<Route path="/patrocinadores" element={<Patrocinadores />} />
				<Route path="/PoliticasPrivacidad" element={<PoliticasPrivacidad />} />
				<Route path="/AvisoLegal" element={<AvisoLegal />} />
				<Route path="/Copa3x3" element={<Copa3x3 />} />
				<Route path="/Estadisticas" element={<Estadisticas />} />
				<Route path="/EstadisticasFem" element={<EstadisticasFem />} />
				<Route path="/temporada" element={<Temporada />} />
				<Route path="/historicas" element={<Historicas />} />
				<Route path="/historicoEquipo" element={<HistoricoEquipo />} />
				<Route path="/temporadaIndividuales" element={<TemporadaIndividuales />} />
				<Route path="/temporadaEquipo" element={<TemporadaEquipo />} />
				<Route path="/detallesEstadisticasTI/:id" element={<DetallesEstadisticasTI />} />
				<Route path="/detallesEstadisticasTIFem/:id" element={<DetallesEstadisticasTIFem />} />
				<Route path="/detallesEstadisticasTE/:id" element={<DetallesEstadisticasTE />} />
				<Route path="/detallesEstadisticasTEFem/:id" element={<DetallesEstadisticasTEFem />} />
				<Route path="/detallesEstadisticasHE/:id" element={<DetallesEstadisticasHE />} />
				<Route path="/detallesEstadisticasHEFem/:id" element={<DetallesEstadisticasHEFem />} />
				<Route path="/sorteo" element={<Sorteo />} />
				<Route path="/politicaPrivacidadSorteo" element={<PoliticaPrivacidad />} />
				<Route path="/basesDelSorteo" element={<BasesDelSorteo />} />
				<Route path="/historicasFem" element={<HistoricasFem />} />
				<Route path="/historicoEquipoFem" element={<HistoricoEquipoFem />} />
				<Route path="/temporadaFem" element={<TemporadaFem />} />
				<Route path="/temporadaEquipoFem" element={<TemporadaEquipoFem />} />
				<Route path="/temporadaIndividualesFem" element={<TemporadaIndividualesFem />} />

				</Routes>
		</BrowserRouter>
	);
}

export default App;
