import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import PageHeaderFem from "../../component/layout/pageheaderFem";

const DetallesEstadisticasTEFem = () => {
  const { id } = useParams(); // Obtiene el id (nombre de la estadística) de la URL
  const [clasificacion, setClasificacion] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(/_/g, ' ')            // Reemplaza guiones bajos por espacios
      .replace(/x/g, ' por ')            // Reemplaza las "x" por espacios
      .replace(/(\d+)/g, ' $1')      // Inserta un espacio antes de los números
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitaliza cada palabra
  };
  

  // Llamada a la API para obtener los datos de la clasificación según el id
  useEffect(() => {
    fetch(`https://laliga3x3.com/backend/api/detallesEstadisticasTEFem.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setClasificacion(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [id]);

  return (
    <React.Fragment>
      <Header />
      <PageHeaderFem title={`ESTADÍSTICAS - ${formatFieldName(id)}`} curPage={'ESTADÍSTICAS'} />
      <section className="player-section padding-top padding-bottom">
        <div className="container">
          <div className="section-header text-center">
            <p className="theme-color text-uppercase ls-2">Clasificación</p>
            <h2>Clasificación por {formatFieldName(id)}</h2>
          </div>
          <div className="section-wrapper d-flex justify-content-center">
            {loading ? (
              <p>Cargando clasificación...</p>
            ) : (
              <table className="clasificacion-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Jugador</th>
                    <th>{formatFieldName(id)}</th> {/* Usamos la función aquí */}
                  </tr>
                </thead>
                <tbody>
                  {clasificacion.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{`${item.nombre}`}</td>
                      <td>{item[id] !== undefined && item[id] !== null ? item[id] : "N/A"}</td> {/* Mostramos el valor del campo correspondiente */}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <style jsx="true">{`
          .player-section {
            padding: 50px 0;
            color: white;
          }

          .section-header p {
            font-size: 18px;
            color: #FF3888;
            margin-bottom: 10px;
          }

          .section-header h2 {
            font-size: 36px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 20px;
          }

          .clasificacion-table {
            width: 100%;
            max-width: 900px;
            background-color: transparent;
            border-collapse: collapse;
            text-align: center;
            color: white;
          }

          .clasificacion-table th,
          .clasificacion-table td {
            padding: 15px;
            border: 1px solid #fff;
          }

          .clasificacion-table th {
            background-color: #FF3888;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
          }

          .clasificacion-table tr:nth-child(even) {
            background-color: rgba(255, 255, 255, 0.1);
          }

          .clasificacion-table tr:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }

          @media (max-width: 768px) {
            .clasificacion-table {
              font-size: 14px;
            }
            .section-header h2 {
              font-size: 28px;
            }
          }
        `}</style>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default DetallesEstadisticasTEFem;
