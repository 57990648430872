import React, { Component } from "react";
import { Link } from "react-router-dom";
import SocialMedia from "../socialmedia";
import SocialMediaEquipos from "../socialmediaEquipos";

const subtitle = "Listado Equipos Femeninos";
const title = "Revisa los equipos de la liga femenina";

class ListadoEquiposFem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipos: [],
      loading: true,
    };
  }

  componentDidMount() {
    fetch("https://laliga3x3.com/backend/api/listadoEquiposFem.php")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ equipos: data, loading: false });
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }

  render() {
    const { equipos, loading } = this.state;

    return (
      <section className="player-section padding-top padding-bottom">
        <div className="container">
          <div className="section-header">
            <p>{subtitle}</p>
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row g-4">
              {loading ? (
                <p>Cargando equipos...</p>
              ) : (
                equipos.map((equipo, i) => (
                  <div className="col-lg-4 col-sm-6 col-12" key={i}>
                    <div
                      className="player-item-2 text-center"
                      style={{
                        backgroundImage: `url(https://laliga3x3.com/backend/DOCS/logosEquipos/${equipo.imagenfondo2})`,
                      }}
                    >
                      <div className="player-inner-2">
                        <div className="player-thumb-2">
                          <Link to={`/equipoFem/${equipo.id}`}>
                            <img
                              src={`https://laliga3x3.com/backend/DOCS/logosEquipos/${equipo.logo}`}
                              alt={`Logo de ${equipo.nombre}`}
                            />
                          </Link>
                        </div>
                        <div className="player-content-2">
                          <Link to={`/equipo/${equipo.id}`}>
                            <h2>{equipo.nombre}</h2>
                          </Link>
                          <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                            <SocialMediaEquipos insta={equipo.instagram} />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Estilos CSS en la misma página */}
        <style jsx="true">{`
          .player-section {
            padding: 50px 0;
            background-color: #1a1f71;
            color: white;
          }

          .section-header p {
            font-size: 18px;
            color: #FF3888;
            margin-bottom: 10px;
          }

          .section-header h2 {
            font-size: 36px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 20px;
          }

          .player-item-2 {
            background-size: cover;
            background-position: center;
            border-radius: 15px;
            padding: 20px;
          }

          .player-thumb-2 img {
            width: 220px; /* Ancho del logo */
            height: 220px; /* Alto del logo */
            object-fit: cover;
            border-radius: 50%;
            margin-bottom: 15px;
          }

          .player-content-2 h2 {
            font-size: 24px;
            color: white;
          }

          .match-social-list {
            list-style: none;
            padding: 0;
            margin: 0;
          }

          .match-social-list li {
            display: inline-block;
            margin: 0 10px;
          }
        `}</style>
      </section>
    );
  }
}

export default ListadoEquiposFem;
