import { Component } from "react";
import { Link } from "react-router-dom"; // Importa Link para la navegación

const subtitle = "Los 10 primeros clasificados";
const title = "Clasificación Masculina";

class PrimerosClasificados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clasificados: [],
      loading: true,
    };
  }

  // Llamada a la API cuando el componente se monta
  componentDidMount() {
    fetch("https://laliga3x3.com/backend/api/PrimerosClasificados.php")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ clasificados: data, loading: false });
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }

  render() {
    const { clasificados, loading } = this.state;

    const containerStyle = {
      padding: "50px 0",
      backgroundSize: "cover",
      textAlign: "center",
      marginBottom: "40px",
      backgroundImage: "url(/assets/images/cta/bg.jpg)", // Mismo fondo que las noticias
      height: "100%", // Asegura que ocupe todo el espacio disponible
      display: "flex", // Usar flex para centrar el contenido
      flexDirection: "column",
      justifyContent: "space-between",
    };

    const headerStyle = {
      textAlign: "center",
      marginBottom: "20px",
    };

    const subtitleStyle = {
      fontSize: "18px",
      color: "#efb12f", // Color del subtítulo
      marginBottom: "10px",
    };

    const titleStyle = {
      fontSize: "28px", // Tamaño más pequeño
      color: "#fff", // Blanco para el título
      fontWeight: "bold",
      marginBottom: "20px",
    };

    const tableStyle = {
      width: "100%",
      maxWidth: "600px", // Más pequeño que la tabla original
      margin: "0 auto",
      backgroundColor: "transparent",
      borderCollapse: "collapse",
      textAlign: "center",
      color: "white",
    };

    const thTdStyle = {
      padding: "10px", // Tamaño más pequeño
      border: "1px solid #fff",
    };

    const thStyle = {
      backgroundColor: "#efb12f",
      color: "white",
      fontWeight: "bold",
      textTransform: "uppercase",
    };

    return (
      <div className="classification-section" style={containerStyle}>
        <div className="container">
          <div className="section-header" style={headerStyle}>
            <p style={subtitleStyle}>{subtitle}</p>
            <h2 style={titleStyle}>{title}</h2>
          </div>
          <div className="section-wrapper">
            {loading ? (
              <p style={{ color: "#fff" }}>Cargando clasificación...</p>
            ) : (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={{ ...thTdStyle, ...thStyle }}>#</th>
                    <th style={{ ...thTdStyle, ...thStyle }}>Equipo</th>
                    <th style={{ ...thTdStyle, ...thStyle }}>Puntos</th>
                  </tr>
                </thead>
                <tbody>
                  {clasificados.slice(0, 10).map((item, index) => (
                    <tr key={index}>
                      <td style={thTdStyle}>{index + 1}</td>
                      <td style={thTdStyle}>
                        {/* Enlace dinámico para ir a la página del equipo */}
                        <Link to={`/equipo/${item.id}`} style={{ color: "#fff", textDecoration: "none" }}>
                          {item.nombre}
                        </Link>
                      </td>
                      <td style={thTdStyle}>{item.puntos} pts</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PrimerosClasificados;
