import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import PlayerSectionTwo from "../../component/section/playertwo";
import CtaSection from "../../component/section/cta";
import ListadoEquipos from "../../component/section/mios/ListadoEquipos";
import ListadoEquiposFem from "../../component/section/mios/ListadoEquiposFem";
import PageHeaderFem from "../../component/layout/pageheaderFem";

class EquiposFem extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeaderFem title={'EQUIPOS FEMENINOS'} curPage={'EQUIPOS'} />
                <ListadoEquiposFem />
                {/* <CtaSection imgUrl={'assets/images/cta/02.png'} /> */}
                <Footer />
            </Fragment>
        );
    }
}
 
export default EquiposFem;