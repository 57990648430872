import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";

import BannerInicio from "../../component/section/mios/BannerInicio";
import EquiposInicio from "../../component/section/mios/EquiposInicio";
import NoticiaInicio from "../../component/section/mios/Noticiainicio";
import ProximaSede from "../../component/section/mios/ProximaSede";
import PrimerosClasificados from "../../component/section/mios/PrimerosClasificacion";
import PatrocinadoresInicio from "../../component/section/mios/PatrocinadoresInicio";
import ProximaSedeFem from "../../component/section/mios/ProximaSedeFem";
import PrimerosClasificadosFem from "../../component/section/mios/PrimerosClasificacionFem";

class Inicio extends Component {
  render() {
    const styles = {
      gridContainer: {
        display: "grid",
        gap: "20px", // Espacio uniforme entre los elementos
        margin: "20px 0",
      },
      gridItem: {
        borderRadius: "10px",
        overflow: "hidden",
      },
      responsiveContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)", // Dos columnas para cada sección
        gap: "20px",
        marginTop: "20px",
      },
    };

    return (
      <Fragment>
        <Header />
        <BannerInicio />
        <div className="grid-container" style={styles.gridContainer}>
          <div className="grid-item" style={styles.gridItem}>
            <NoticiaInicio />
          </div>
        </div>

        <div className="responsive-container" style={styles.responsiveContainer}>
          <div className="grid-item" style={styles.gridItem}>
            <ProximaSede />
          </div>
          <div className="grid-item" style={styles.gridItem}>
            <ProximaSedeFem />
          </div>
        </div>

        <div className="responsive-container" style={styles.responsiveContainer}>
          <div className="grid-item" style={styles.gridItem}>
            <PrimerosClasificados />
          </div>
          <div className="grid-item" style={styles.gridItem}>
            <PrimerosClasificadosFem />
          </div>
        </div>

        <PatrocinadoresInicio />
        <Footer />

        <style jsx="true">{`
          @media (max-width: 768px) {
            .grid-container {
              display: flex !important;
              flex-direction: column;
              gap: 20px;
              margin: 0 10px;
            }
            .responsive-container {
              display: flex !important;
              flex-direction: column;
              gap: 20px;
            }
            .grid-item {
              width: 100%;
            }
          }
        `}</style>
      </Fragment>
    );
  }
}

export default Inicio;
