import React, { useState, useEffect } from "react";

const ProximaSede = () => {
  const [sede, setSede] = useState("");
  const [fecha, setFecha] = useState("");
  const [jornada, setJornada] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Función para obtener los datos de la API
  const obtenerProximaSede = async () => {
    try {
      const response = await fetch("https://laliga3x3.com/backend/api/proximaSede.php");
      const data = await response.json();

      // Verificar si los datos tienen la estructura esperada
      if (data && data.nombre && data.fecha && data.id) {
        setSede(data.nombre);
        setFecha(data.fecha);
        setJornada(data.id);
        setLoading(false);
      } else {
        console.error("Estructura de datos inesperada:", data);
        setError("Datos no válidos");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error al obtener la próxima sede:", error);
      setError("Error al obtener los datos");
      setLoading(false);
    }
  };

  // useEffect para llamar a la API al cargar el componente
  useEffect(() => {
    obtenerProximaSede();
  }, []); // Solo se ejecuta una vez al cargar el componente

  if (loading) {
    return <p>Cargando la información...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="cta-section">
      <div className="container">
        <div className="cta-wrapper item-layer">
          <div
            className="cta-item px-4 px-sm-5 pt-4 pt-sm-5 pt-lg-0"
            style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}
          >
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="cta-content text-center">
                  <p className="theme-color text-uppercase ls-2" style={{ marginTop: "50px", marginBottom:40 }}>Próxima Sede Masculina</p>
                  <h2 className="mb-5">
                    Jornada {jornada} - <span className="theme-color">{fecha}</span>
                  </h2>
                  <h1
                    className="fw-bold text-uppercase"
                    style={{
                      fontSize: "60px",
                      fontWeight: "bold",
                      letterSpacing: "0.05em",
                      color: "#ffffff",
                      WebkitTextStroke: "2px #ff0055",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "white",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {sede}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProximaSede;
