import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import PlayerSectionTwo from "../../component/section/playertwo";
import CtaSection from "../../component/section/cta";
import ListadoEquipos from "../../component/section/mios/ListadoEquipos";

class Equipos extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'EQUIPOS MASCULINOS'} curPage={'EQUIPOS'} />
                <ListadoEquipos />
                {/* <CtaSection imgUrl={'assets/images/cta/02.png'} /> */}
                <Footer />
            </Fragment>
        );
    }
}
 
export default Equipos;