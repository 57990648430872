import React, { useState, useEffect, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeaderTwo from "../../component/layout/pageheadertwo";

const EquipoFem = () => {
  const { id } = useParams(); // Obtén el ID del equipo de los parámetros de la URL
  const [equipo, setEquipo] = useState({});
  const [jugadores, setJugadores] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch team details
  useEffect(() => {
    fetch(`https://laliga3x3.com/backend/api/detallesEquipo.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setEquipo(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching equipo data: ", error);
      });
  }, [id]);

  // Fetch players for the team
  useEffect(() => {
    fetch(`https://laliga3x3.com/backend/api/listadoJugadores2.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setJugadores(data);
      })
      .catch((error) => {
        console.error("Error fetching jugadores data: ", error);
      });
  }, [id]);

  return (
    <Fragment>
      <Header />
      <PageHeaderTwo title={equipo.nombre} imagen={equipo.logo} />

      {/* Información del Equipo */}
      <div className="about-team padding-top padding-bottom">
        <div className="container">
          {loading ? (
            <p>Cargando equipo...</p>
          ) : (
            <Fragment>
              <div className="section-header text-center">
                <h2 className="mb-3">{equipo.nombre}</h2>
                <p>{equipo.descripcion || "Próximamente disponible"}</p>
              </div>

              {/* Estadísticas del Equipo con recuadros */}
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="stat-box">
                    <h4>Puntos por Partido</h4>
                    <p>{equipo.puntosxpartido || "N/A"}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="stat-box">
                    <h4>Asistencias por Partido</h4>
                    <p>{equipo.asistenciasxpartido || "N/A"}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="stat-box">
                    <h4>Rebotes por Partido</h4>
                    <p>{equipo.rebotesxpartido || "N/A"}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="stat-box">
                    <h4>% Tiros de 1</h4>
                    <p>{equipo.tiros1_porcentaje !== undefined ? `${equipo.tiros1_porcentaje}%` : "N/A"}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="stat-box">
                    <h4>% Tiros de 2</h4>
                    <p>{equipo.tiros2_porcentaje !== undefined ? `${equipo.tiros2_porcentaje}%` : "N/A"}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="stat-box">
                    <h4>% Tiros Libres</h4>
                    <p>{equipo.tiroslibre_porcentaje !== undefined ? `${equipo.tiroslibre_porcentaje}%` : "N/A"}</p>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>

      {/* Jugadores */}
      <div className="player-section padding-bottom">
        <div className="container">
          <div className="section-header text-center">
            <h2>Jugadoras</h2>
          </div>
          <div className="section-wrapper">
            <div className="row g-4 justify-content-center">
              {jugadores.map((jugador, i) => (
                <div className="col-lg-4 col-sm-6 col-12" key={i}>
                  <Link to={`/jugador/${jugador.id}`}> 
                    <div className="player-item">
                      <div className="player-inner">
                        <div className="player-thumb">
                          <img
                            src={`https://laliga3x3.com/backend/DOCS/jugadores/${jugador.imagen}`}
                            alt={`${jugador.nombre}`}
                          />
                        </div>
                        <div className="player-content text-center">
                          <div className="player-info-list">
                            <h3 className="mb-0">{jugador.nombre} {jugador.apellido}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Estilos para las estadísticas */}
      <style jsx="true">{`
        .stat-box {
          background-color: #f4f4f4;
          border-radius: 10px;
          padding: 20px;
          text-align: center;
          margin-bottom: 20px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
        }
        .stat-box h4 {
          font-size: 18px;
          font-weight: bold;
          color: #333;
          margin-bottom: 10px;
        }
        .stat-box p {
          font-size: 24px;
          font-weight: bold;
          color: #000;
        }
        .stat-box:hover {
          transform: translateY(-10px);
        }
      `}</style>
    </Fragment>
  );
};

export default EquipoFem;
